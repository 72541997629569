import React from "react";
import theme from "theme";
import { Theme, Link, Image, Box, Section, Text } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"privacy"} />
		<Helmet>
			<title>
				Privacy policy
			</title>
			<meta name={"description"} content={"A cognitive editor for Java and Kotlin"} />
			<meta property={"og:title"} content={"Cognitive Editor"} />
			<meta property={"og:description"} content={"A cognitive editor for Java and Kotlin\n"} />
		</Helmet>
		<Image
			position="absolute"
			bottom="auto"
			md-width="600px"
			md-top="300px"
			lg-right="-200px"
			lg-top="0px"
			src="https://uploads.quarkly.io/6123949e957019001e284458/images/backgroung-hero.svg?v=2021-09-21T22:07:12.314Z"
			right="0px"
			top="130px"
			z-index="-10"
		/>
		<Section background="linear-gradient(264.47deg, #000848 29.39%, #000848 93.49%)" padding="36px 0 36px 0" quarkly-title="Header">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				empty-border-width="1px"
				width="70%"
				align-items="center"
				sm-width="50%"
				display="flex"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-style="solid"
				empty-border-color="LightGray"
			>
				<Components.QuarklycommunityKitMobileSidePanel md-z-index="9">
					<Override slot="Button Text" md-display="none" />
					<Override slot="Button Icon" md-color="--white" md-font="36px sans-serif" />
					<Override slot="Cross" md-color="--dark" md-font="36px sans-serif" />
					<Override slot="Wrapper" md-z-index="9" />
					<Override
						slot="Content"
						md-justify-content="flex-start"
						background="rgba(255, 255, 255, 0)"
						justify-content="center"
						align-items="center"
						padding="16px 0px 16px 16px"
						md-background="--color-light"
						md-z-index="9"
					/>
					<Override slot="Children" display="flex" align-items="center" justify-content="center" />
					<Box
						justify-content="flex-end"
						md-flex-direction="column"
						md-margin="40px 0px 13px 0px"
						md-font="--headline4"
						display="flex"
						align-items="center"
					>
						<Link
							color="--white"
							margin="0px 20px 0px 20px"
							md-margin="0px 0px 13px 0px"
							md-font="--lead"
							md-color="--indigo"
							href="/index"
							font="normal 600 18px/1.5 --fontFamily-googleInter"
							text-decoration-line="initial"
						>
							Home
						</Link>
						<Link
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							href="/index#features"
							font="--base"
							text-decoration-line="initial"
							hover-opacity="1"
							md-font="--lead"
							md-color="--indigo"
							color="--grey"
							margin="0px 20px 0px 20px"
							opacity="0.8"
						>
							Features
						</Link>
						<Link
							color="--grey"
							margin="0px 20px 0px 20px"
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							md-font="--lead"
							href="/index#public-beta"
							font="--base"
							text-decoration-line="initial"
							opacity="0.8"
							hover-opacity="1"
							md-color="--indigo"
						>
							Public Beta
						</Link>
						<Link
							hover-opacity="1"
							md-font="--lead"
							md-color="--indigo"
							font="--base"
							color="--grey"
							opacity="0.8"
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							href="/index#contact"
							text-decoration-line="initial"
							margin="0px 8px 0px 20px"
						>
							Contact
						</Link>
						<Link
							hover-opacity="1"
							md-font="--lead"
							md-color="--indigo"
							font="--base"
							color="--grey"
							opacity="0.8"
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							href="/index#research"
							text-decoration-line="initial"
							margin="0px 8px 0px 20px"
						>
							Research
						</Link>
					</Box>
				</Components.QuarklycommunityKitMobileSidePanel>
			</Box>
		</Section>
		<Section background="linear-gradient(264.47deg, #000848 29.39%, #000848 93.49%)" padding="36px 0 80px 0" quarkly-title="HeroBlock">
			<Override
				slot="SectionContent"
				md-z-index="7"
				flex-direction="row"
				position="relative"
				z-index="9"
				md-flex-wrap="wrap"
			/>
			<Box
				empty-border-width="1px"
				align-items="flex-start"
				md-width="100%"
				display="flex"
				flex-direction="column"
				empty-border-color="LightGray"
				width="70%"
				justify-content="center"
				padding="0px 180px 0px 0px"
				lg-padding="0px 90px 0px 0px"
				empty-min-height="64px"
				empty-border-style="solid"
				empty-min-width="64px"
				md-padding="0px 0px 0px 0px"
			>
				<Text
					letter-spacing="1%"
					lg-text-align="left"
					lg-font="normal 700 40px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 28px/1.2 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					font="--headline1"
					color="--white"
					sm-font="normal 500 30px/1.2 &quot;Inter&quot;, sans-serif"
				>
					Privacy Policy
				</Text>
			</Box>
		</Section>
		<Section>
			<Text>
				The use of this website may involve the processing of personal data. To make this processing transparent to you, we would like to provide you with an overview of this processing. To ensure fair processing, we would also like to inform you about your rights under the European General Data Protection Regulation (GDPR) and the Bundesdatenschutzgesetz (BDSG).
			</Text>

			<Text font="--headline4" color="--indigo">Contact</Text>
			<Text>
				monochromata UG (haftungsbeschränkt)<br/>
				Friedrichstraße 95<br/>
				10117 Berlin<br/>
				<br/>
				If you have any questions or suggestions regarding data protection or this privacy policy, please contact us by email at info@monochromata.de or by phone at +49 30 531 126 09.
			</Text>

			<Text font="--headline4" color="--indigo">Legal basis</Text>
			<Text>
				We process personal data in compliance with the relevant data protection regulations, in particular GDPR and BDSG. We only process data on the basis of legal permission. When you use this website, we process personal data only with your consent (Section 25 (1) of the Telekommunikation-Digitale-Dienste-Datenschutz-Gesetz (TDDG) or Article 6 (1) (a) GDPR), for the performance of a contract to which you are party, or at your request to take steps prior to entering into a contract (Article 6 (1) (b) GDPR ), for compliance with a legal obligation (Art. 6 (1) (c) GDPR) or if processing is necessary for the purposes of the legitimate interests pursued by us or by a third party, except where such interests are overridden by your interests or fundamental rights and freedoms which require protection of personal data (Art. 6 (1) (f) GDPR).
			</Text>

			<Text font="--headline4" color="--indigo">Duration of storage</Text>
			<Text>
				Unless otherwise stated, we only store data for as long as is necessary to achieve the purpose of the processing or to fulfill our contractual or legal obligations. Such legal storage obligations may arise in particular from commercial or tax law.
			</Text>

			<Text font="--headline4" color="--indigo">Categories of data recipients</Text>
			<Text>
				We use processors to process your data. The processing operations carried out by such processors include, for example, the provision of this website. A processor is a natural or legal person, public authority, agency or other body that processes personal data on behalf of the controller. Processors do not use the data for their own purposes, but only process the data on behalf of the controller and are contractually obliged to ensure that appropriate technical and organizational measures are in place to protect the data. In addition, we may transfer your personal data to bodies such as postal and delivery services, our principal bank, tax advisors/auditors or the tax authorities. Further recipients may arise from the following information.
			</Text>

			<Text font="--headline4" color="--indigo">Data transfer to third countries</Text>
			<Text>
				Our data processing may involve the transfer of certain personal data to third countries, i.e. countries where the GDPR is not applicable law. Such a transfer is carried out in a permissible manner if the European Commission has determined that an adequate level of data protection is provided in such a third country. If no such adequacy decision has been made by the European Commission, personal data will only be transferred to a third country if suitable guarantees are provided in accordance with Article 46 of the GDPR or if one of the conditions of Article 49 of the GDPR is met.
			</Text>
			<Text>
				If no adequacy decision has been made and nothing to the contrary is stated below, we use the EU standard data protection clauses as suitable guarantees for the transfer of personal data to third countries. You have the option to receive or view a copy of these EU standard data protection clauses. To do so, please contact us at the address provided.
			</Text>
			<Text>
				If you consent to the transfer of personal data to third countries, the transfer will be carried out on the legal basis of Art. 49 (1) (a) GDPR.
			</Text>

			<Text font="--headline4" color="--indigo">Server log files</Text>
			<Text>
				When using our website for purely informational purposes, general information that your browser transmits to our server is initially stored automatically (i.e. not via registration). This includes by default: browser type/version, operating system used, page accessed, previously visited page (referrer URL), anonymized IP address, date and time of server request and HTTP status code. The last part of the IP address is exchanged for anonymized IP addresses. The processing is carried out to protect our legitimate interests and is based on the legal basis of Art. 6 para. 1 letter f DSGVO. This processing is for the technical administration and security of the website. The stored data will be deleted after seven days, unless there is a justified suspicion of unlawful use based on specific indications and further examination and processing of the information is necessary for this reason. We are not able to identify you as a data subject on the basis of the stored information. Articles 15 to 22 of the GDPR therefore do not apply in accordance with Article 11 (2) of the GDPR, unless you provide additional information that enables your identification in order to exercise your rights under these articles.
			</Text>

			<Text font="--headline4" color="--indigo">Cookies</Text>
			<Text>
				This website does not use cookies.
			</Text>

			<Text font="--headline4" color="--indigo">Your rights</Text>
			<Text>
				As the data subject, you have the right to assert your rights against us. In particular, you have the following rights:
			</Text>
			<Text>
				• to request information about whether and, if so, to what extent we process personal data about you or not. (Art. 15 GDPR and § 34 BDSG)<br/>
				• to request the correction of your data (Art. 16 GDPR)<br/>
				• to request the deletion of your personal data (Art. 17 GDPR and § 35 BDSG)<br/>
				• to restrict the processing of your personal data (Art. 18 GDPR)<br/>
				• receive the personal data concerning you, which you have provided to us, in a structured, commonly used and machine-readable format and have the right to transmit those data to another controller (Art. 20 GDPR)<br/>
			</Text>
			<Text>
				If you have given us separate consent to the data processing, you can revoke this consent at any time in accordance with Art. 7 (3) GDPR. Such a revocation does not affect the lawfulness of the processing that has taken place up to the revocation on the basis of the consent.
			</Text>

			<Text font="--headline4" color="--indigo">Complaint to a supervisory authority</Text>
			<Text>
				If you believe that the processing of personal data concerning you infringes the provisions of the GDPR, you have the right to lodge a complaint with a supervisory authority in accordance with Article 77 of the GDPR.
			</Text>

			<Text font="--headline4" color="--indigo">Right to object</Text>
			<Text>
				In accordance with Art. 21 (1) GDPR, you have the right to object to any processing based on the legal basis of Art. 6 (1) (e) or (f) GDPR. If we process personal data about you for the purpose of direct marketing, you can object to this processing in accordance with Art. 21 (2) and (3) GDPR.
			</Text>

			<Text font="--headline4" color="--indigo">Changes to the privacy policy</Text>
			<Text>
				We will publish new versions of the privacy policy on this page.
			</Text>
			<Text>
				This privacy policy was last updated on August 20, 2024.
			</Text>
		</Section>
		<Section background="--color-indigo" padding="20px 0 40px 0" md-padding="40px 0 0px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-width="1px"
				display="flex"
				md-width="100%"
				md-align-items="center"
				flex-direction="column"
				padding="16px 16px 16px 16px"
				md-justify-content="center"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="25%"
			>
				<Link
					text-decoration-line="initial"
					transition="opacity 0.3s ease 0s"
					hover-opacity="1"
					margin="0px 0px 10px 0px"
					font="--base"
					href="/"
					color="--white"
					opacity=".8"
				>
					Home
				</Link>
				<Link
					opacity=".8"
					text-decoration-line="initial"
					transition="opacity 0.3s ease 0s"
					hover-opacity="1"
					margin="0px 0px 10px 0px"
					font="--base"
					href="https://monochromata.de/doc/"
					color="--white"
				>
					Documentation
				</Link>
				<Link
					opacity=".8"
					text-decoration-line="initial"
					transition="opacity 0.3s ease 0s"
					hover-opacity="1"
					margin="0px 0px 10px 0px"
					font="--base"
					href="https://app.slack.com/client/T07MX4MKU0M/C07MZTSLUTV"
					color="--white"
				>
					Slack
				</Link>
				<Link
					opacity=".8"
					text-decoration-line="initial"
					transition="opacity 0.3s ease 0s"
					hover-opacity="1"
					margin="0px 0px 10px 0px"
					font="--base"
					href="https://github.com/monochromata/cognitive-editor/releases"
					color="--white"
				>
					Releases on GitHub
				</Link>
				<Link
					color="--white"
					opacity=".8"
					text-decoration-line="initial"
					transition="opacity 0.3s ease 0s"
					hover-opacity="1"
					margin="0px 0px 10px 0px"
					font="--base"
					href="https://github.com/monochromata/cognitive-editor/issues"
				>
					Issues on GitHub
				</Link>
			</Box>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				width="25%"
				display="flex"
				md-width="100%"
				md-justify-content="center"
				empty-border-width="1px"
				empty-border-style="solid"
				flex-direction="column"
				padding="16px 16px 16px 16px"
				md-align-items="center"
			>
				<Link
					color="--white"
					opacity=".8"
					text-decoration-line="initial"
					transition="opacity 0.3s ease 0s"
					hover-opacity="1"
					margin="0px 0px 10px 0px"
					font="--base"
					href="/privacy"
				>
					Privacy policy
				</Link>
				<Link
					color="--white"
					opacity=".8"
					text-decoration-line="initial"
					transition="opacity 0.3s ease 0s"
					hover-opacity="1"
					margin="0px 0px 10px 0px"
					font="--base"
					href="/impressum"
				>
					Impressum
				</Link>
			</Box>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6123949e957019001e284456"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});