export default {
	"pages": {
		"root": {
			"id": "root",
			"pageUrl": "root",
			"name": "root",
			"children": [
				"655d1125fdcae00021d9f9e6",
				"655d1125fdcae00021d9f9ea",
				"6560a52c8e2e8e002173a635",
				"656f6ea3009abe001f001ead"
			]
		},
		"655d1125fdcae00021d9f9e6": {
			"id": "655d1125fdcae00021d9f9e6",
			"name": "404",
			"pageUrl": "404",
			"seo": {
				"title": "Not Found"
			}
		},
		"655d1125fdcae00021d9f9ea": {
			"id": "655d1125fdcae00021d9f9ea",
			"name": "index",
			"pageUrl": "index",
			"seo": {
				"title": "Cognitive Editor"
			}
		},
		"6560a52c8e2e8e002173a635": {
			"pageUrl": "index1",
			"id": "6560a52c8e2e8e002173a635",
			"name": "index backup"
		},
		"656f6ea3009abe001f001ead": {
			"pageUrl": "impressum",
			"id": "656f6ea3009abe001f001ead",
			"name": "impressum",
			"seo": {
				"title": "Impressum"
			}
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {},
		"seo": {
			"background_win10": "#000848",
			"og:title": "Cognitive Editor",
			"og:description": "A cognitive editor for Java and Kotlin\n",
			"og:image": "",
			"title": "Cognitive Editor",
			"description": "A cognitive editor for Java and Kotlin"
		}
	}
}